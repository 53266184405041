<template>
	<v-menu
		content-class="fab-action-menu"
		v-model="isOpened"
		:close-on-content-click="true"
		:close-on-click="closeOnClick"
		:offset-y="true"
		:nudge-top="8"
		:min-width="350"
		tile
		top
		:z-index="100"
		@input="handleInputChange">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" fab elevation="0" @click="fabToggle">
				<v-icon>icon-plus</v-icon>
			</v-btn>
		</template>

		<v-list v-if="isOpened">
			<v-list-item class="fab-action">
				<ActionButton
					:button-id="'fab-action-sync'"
					:project-id="projectId"
					:button-text="'Add Photos or Documents'"
					:button-icon="'icon-cloud-stroke-upload'"
					:button-type="'textFab'"
					:action="'upload-photo'">
				</ActionButton>
			</v-list-item>
			<v-list-item class="fab-action">
				<ActionButton
					:button-id="'fab-action-sync'"
					:project-id="projectId"
					:button-text="'Take Photos'"
					:button-icon="'icon-camera-solid'"
					:button-type="'textFab'"
					:action="'photo'"></ActionButton>
			</v-list-item>
			<v-list-item class="fab-action">
				<ActionButton
					:button-id="'fab-action-sync'"
					:project-id="projectId"
					:button-text="'Request a Recording'"
					:button-icon="'icon-paper-plane-stroke'"
					:button-type="'textFab'"
					:action="'request-recording'"></ActionButton>
			</v-list-item>
			<v-list-item class="fab-action">
				<ActionButton
					:button-id="'fab-action-sync'"
					:project-id="projectId"
					:button-text="'Start a Recording'"
					:button-icon="'icon-recording-circle'"
					:button-type="'textFab'"
					:action="'async-recording'">
				</ActionButton>
			</v-list-item>
			<v-list-item class="fab-action">
				<ActionButton
					:button-id="'fab-action-sync'"
					:project-id="projectId"
					:button-text="'Schedule a Live Video Call'"
					:button-icon="'icon-calendar-stroke'"
					:button-type="'textFab'"
					:action="'schedule'"></ActionButton>
			</v-list-item>
			<v-list-item class="fab-action">
				<ActionButton
					:button-id="'fab-action-sync'"
					:project-id="projectId"
					:button-text="'Start a Live Video Call'"
					:button-icon="'icon-conversation-stroke'"
					:button-type="'textFab'"
					:action="'live-call'">
				</ActionButton>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
	import ActionButton from "./ActionButton.vue";
	import { EventBus } from "../helpers/eventBus";
	import api from "../api";

	export default {
		name: "ProjectFAB",
		props: {
			projectId: {
				type: String,
				required: true,
			},
			shouldShowProjectActionFAB: Boolean,
		},
		components: {
			ActionButton,
		},
		data() {
			return {
				isOpened: false,
				fabTab: 0,
				screenWidth: window.innerWidth,
				screenHeight: window.innerHeight,
				closeOnClick: true,
				me: null,
			};
		},
		async mounted() {
			EventBus.$on("DisableCloseOnClick", () => {
				this.closeOnClick = false;
			});

			EventBus.$on("EnableCloseOnClick", () => {
				this.closeOnClick = true;
			});
			this.me = await api.getMe(true, true, true);
		},
		methods: {
			open() {
				this.isOpened = true;
			},
			close() {
				this.isOpened = false;
			},
			fabToggle() {
				if (this.$props.shouldShowProjectActionFAB) {
					this.isOpened = !this.isOpened;
				} else {
					this.$root.$freeTrialEndedDialog.open(this.me.organization);
				}
				//this.handleInputChange();
			},
			handleInputChange() {
				this.close();
				this.$emit("change", this.isOpened);
			},
		},
	};
</script>
<style>
	/* These styles can't be in scoped as they use variables from the root page */
	.action-fab-live-call {
		background-color: var(--live-call-fab) !important;
	}

	.action-fab-live-call i {
		color: var(--live-call-fab-icon) !important;
	}

	.action-fab-photo {
		background-color: var(--photo-fab) !important;
	}

	.action-fab-photo i {
		color: var(--photo-fab-icon) !important;
	}

	.action-fab-upload-photo {
		background-color: var(--upload-photo-fab) !important;
	}

	.action-fab-upload-photo i {
		color: var(--upload-photo-fab-icon) !important;
	}

	.action-fab-schedule {
		background-color: var(--schedule-fab) !important;
	}

	.action-fab-schedule i {
		color: var(--schedule-fab-icon) !important;
	}

	.action-fab-async-recording {
		background-color: var(--async-recording-fab) !important;
	}

	.action-fab-async-recording i {
		color: var(--async-recording-fab-icon) !important;
	}

	.action-fab-request-recording {
		background-color: var(--request-recording-fab) !important;
	}

	.action-fab-request-recording i {
		color: var(--request-recording-fab-icon) !important;
	}
</style>

<style scoped>
	.fab-action {
		margin-top: 12px;
	}

	.fab-action:first-child {
		margin-top: 0;
	}

	.fab-action:hover {
		background-color: #eff3fd;
	}

	#fab-button i {
		font-size: 32px;
	}

	#fab-button::v-deep > span {
		/* margin-left: -10px; */
	}

	.fab-menu {
		min-height: 100vh;
	}

	.fabTabCard {
		min-width: 368px;
	}
	.fab-menu::v-deep .v-tabs:not(.v-tabs--vertical) .v-tab {
		white-space: nowrap;
		font-size: 12px;
	}

	.fab-menu::v-deep .v-card {
		box-shadow: none;
	}

	.fab-menu::v-deep > .v-card > .v-card__text .conversation-card {
		min-height: 400px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.fab-menu::v-deep .v-card .v-card__text .v-card__text {
		padding: 0px;
		color: #000;
	}

	.fab-menu::v-deep .contact-name.reset {
		padding-top: 0px;
	}

	.v-application .v-menu__content.fab-action-menu {
		border-radius: 16px !important;
		top: 300px !important;
		left: 1% !important;
	}

	@media (min-width: 950px) {
		.v-application .v-menu__content.fab-action-menu {
			border-radius: 16px !important;
			top: 395px !important;
			left: 62vw !important;
		}
	}

	@media (min-width: 320px) and (max-width: 959px) and (orientation: landscape) {
		.fab-window {
			overflow: scroll;
		}

		.fab-menu::v-deep > .v-card > .v-card__text .conversation-card {
			height: calc(100vh - 100px);
			min-height: 0;
		}
	}

	@media (min-width: 960px) {
		#fab-button {
			bottom: 12px;
		}
	}

	@media (max-width: 414px) {
		.fab-menu::v-deep .v-tabs:not(.v-tabs--vertical) .v-tab {
			font-size: 11px;
		}
	}

	@media (max-width: 375px) {
		.fab-menu::v-deep .v-tabs:not(.v-tabs--vertical) .v-tab {
			font-size: 10px;
		}
	}
</style>
<style>
	#fab-button .v-icon__component {
		width: 32px;
		height: 32px;
	}
</style>
